import { useState, useEffect } from "react";
import f7WebNetwork from "../../common/api/customApi/f7WebNetwork";
import { ModalLayout } from "../HeadlessUI";


type ComponentProps = {
    show: boolean,
    setShow: Function,
    releatedPostIds: Array<number>,
    addPostId: Function,
    deletePostId: Function,
    postId: Number,
}

const ReleatedPostListModal = ({ show, setShow, releatedPostIds, addPostId, deletePostId, postId }: ComponentProps) => {
    const [imageList, setImageList] = useState<Array<any>>([]); 
    const [searchText, setSearchText] = useState("");
 
    useEffect(() => {
        getImageFromLibrary();
    }, []);

    useEffect(() => {

        if (show) {
            getImageFromLibrary();
        }

    }, [show]);

    const getImageFromLibrary = async () => {

        if (searchText.length == 0) {

            return;
        }
        var params = {
            searchKey: searchText,
            postId: postId
        }
        var result = await f7WebNetwork.get("/admin/selectPostFromReleatinSearch", params);
 

        setImageList((result.data || []));
    }

    const getInsertDeleteButton = (row: any) => {
        const { POSTID } = row;
        var postIdList = releatedPostIds || [];
        var index = postIdList.indexOf(POSTID);

        if (index != -1) {

            return (
                // <Button variant="danger" onClick={() => { deletePostId(POSTID) }}> <i className="i-Delete-File"> </i>  Kaldır</Button>
                <button
                    className="btn bg-danger text-white"
                    onClick={() => { deletePostId(POSTID) }}
                >Kaldır</button>
            );
        } else {
            return (
                // <Button variant="success" onClick={() => { addPostId(POSTID) }}> <i className="i-Delete-File"> </i>  Ekle</Button>
                <button
                    className="btn bg-success text-white"
                    onClick={() => { addPostId(POSTID) }}
                >Ekle</button>
            );
        }

    }

    const buttonFormatter = (row: any) => {
        // const rowIndex = commentList.findIndex(w => w.COMMENTID == row2[0]);
        // const row = commentList[rowIndex];
        return getInsertDeleteButton(row);
    }


    const getImageList = () => {


        return (
            <>
                <div style={{ width: "100%", padding: 15, borderBottomWidth: 0.5, borderBottomStyle: "solid", borderColor: "#000" }}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="">
                            Id
                        </div>
                        <div className="">
                            Haber Başlığı
                        </div>
                        <div className="">
                            Kategori
                        </div>
                        <div className="">
                            Actions
                        </div>
                    </div>
                </div>
                {imageList.map((item, index) => (
                    <div key={index+"imageList"} style={{ width: "100%", padding: 15, borderBottomWidth: 1, borderBottomStyle: "solid", borderColor: "#ddd" }}>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="">
                                {item.POSTID}
                            </div>
                            <div className="">
                                {item.POSTTITLE}
                            </div>
                            <div className="">
                                {item.TERMNAME}
                            </div>
                            <div className="">
                                {buttonFormatter(item)}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    return (

        <ModalLayout
            showModal={show}
            toggleModal={() => setShow(false)}
            panelClassName={"w-[90vw]"}
            placement=" justify-center items-start"
            isStatic
        >
            <div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
                <div className="flex justify-between items-center py-2.5 px-4 border-b dark:border-gray-700">
                    <h3 className="font-medium text-gray-600 dark:text-white text-lg">
                        Bağlantılı Haber Ekle
                    </h3>
                    <button
                        className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 dark:text-gray-200"
                        type="button"
                        onClick={() => setShow(false)}
                    >
                        <i
                            className="mdi mdi-close text-2xl"
                            
                        />
                    </button>
                </div>
                <div className="w-full p-2">
                            <input
                                type="text"
                                className="form-input"
                                id="inputPassword3"
                                placeholder="Haber Ara"
                                value={searchText}
                                onChange={(ev) => {
                                    setSearchText(ev.target.value)
                                }}
                            />
                        </div>
                        <div className="w-full p-2">
                            <button   className="btn bg-primary text-white"
                                onClick={getImageFromLibrary}
                            > 
                                <span className="ul-btn__text">Ara</span>
                            </button>
                        </div>
                <div
                    className={` p-4 overflow-y-auto`}
                >
                    {getImageList()}
                </div>
                <div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
                    <button
                        className="btn bg-light text-gray-800 transition-all"
                        onClick={() => setShow(false)}
                    >
                        Kapat
                    </button>
                </div>
            </div>
        </ModalLayout>

    );
};

export default ReleatedPostListModal;




