type CardData = {
	image: string
	name: string
	position: string
	variant: string
}

const SimpleCard = ({ image, name, position, variant }: CardData) => {
	return (
		<div className="card">
			<div className="p-6">
				<div className="flex items-center gap-6">
					<img src={image} className="rounded-full h-16" alt="user" />
					<div className="flex-grow overflow-hidden">
						<h5 className="text-gray-800 dark:text-white mb-1">{name}</h5>
						<p className="mb-2 text-gray-400 font-normal truncate">
							coderthemes@gmail.com
						</p>
						<p className={`${variant} font-semibold text-sm`}>{position}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SimpleCard
