import { PopoverLayout } from './HeadlessUI'
import { Link } from 'react-router-dom'
import React, { ReactNode } from 'react'

interface ProfileMenuItem {
	label: string
	icon: ReactNode
	redirectTo: string
	isDivider?: boolean
}

interface ProfileDropDownProps {
	menuItems: Array<ProfileMenuItem>
	profiliePic?: string
	username: string
	icon?: ReactNode
}
const ProfileDropDown = (props: ProfileDropDownProps) => {
	const PopoverToggler = () => {
		const profilePic = props.profiliePic
		return (
			<>
				<img
					src={profilePic}
					alt="user-image"
					className="rounded-full h-8 w-8"
				/>
				<span className="text-sm mx-2">{props.username}</span>
				<i className="mdi mdi-chevron-down" />
			</>
		)
	}
	return (
		<PopoverLayout
			placement="bottom-end"
			togglerClass="nav-link flex items-center"
			toggler={<PopoverToggler />}
		>
			<div className="fc-dropdown-open:opacity-100 w-44 z-50 transition-[margin,opacity] duration-300 bg-white shadow-lg border rounded py-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800">
				<h6 className="py-2 px-5">Welcome !</h6>
				{(props.menuItems || []).map((item, idx) => {
					return (
						<React.Fragment key={idx}>
							<Link
								to={item.redirectTo}
								className="flex items-center py-2 px-5 text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
							>
								{item.icon}
								<span>{item.label}</span>
							</Link>
							{item.isDivider && (
								<hr className="my-2 -mx-2 border-gray-200 dark:border-gray-700" />
							)}
						</React.Fragment>
					)
				})}
			</div>
		</PopoverLayout>
	)
}

export default ProfileDropDown
