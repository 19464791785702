import React, { Suspense, useEffect } from 'react'
import { useThemeContext } from '../common/context'
import { useViewport } from '../hooks'
import { changeHTMLAttribute } from '../utils'
import Preloader from '../components/Preloader'

const Topbar = React.lazy(() => import('./Topbar'))
const LeftSidebar = React.lazy(() => import('./LeftSideBar'))
const RightSidebar = React.lazy(() => import('./RightSidebar'))
const Footer = React.lazy(() => import('./Footer'))

const loading = () => <div />
interface VerticalLayoutProps {
	children?: any
}
const VerticalLayout = ({ children }: VerticalLayoutProps) => {
	const { settings, updateSidenav } = useThemeContext()
	const { width } = useViewport()

	useEffect(() => {
		changeHTMLAttribute('data-mode', settings.theme)
	}, [settings.theme])

	useEffect(() => {
		changeHTMLAttribute('data-layout-width', settings.mode)
	}, [settings.mode])

	useEffect(() => {
		changeHTMLAttribute('data-topbar-color', settings.topbarTheme)
	}, [settings.topbarTheme])

	useEffect(() => {
		changeHTMLAttribute('data-menu-color', settings.sidenav.theme)
	}, [settings.sidenav.theme])

	useEffect(() => {
		changeHTMLAttribute('data-sidenav-view', settings.sidenav.mode)
	}, [settings.sidenav.mode])

	useEffect(() => {
		changeHTMLAttribute('dir', settings.dir)
	}, [settings.dir])

	useEffect(() => {
		changeHTMLAttribute('data-layout-position', settings.sidenav.position)
	}, [settings.sidenav.position])

	useEffect(() => {
		if (width < 768) {
			updateSidenav({ mode: 'mobile' })
		} else if (width < 1140) {
			updateSidenav({ mode: 'condensed' })
		} else if (width >= 1140) {
			updateSidenav({ mode: 'default' })
		}
	}, [width])

	return (
		<Suspense fallback={loading()}>
			<div className="flex wrapper">
				<Suspense fallback={loading()}>
					<LeftSidebar />
				</Suspense>

				<div className="page-content">
					<Suspense fallback={loading()}>
						<Topbar />
					</Suspense>

					<main className="p-6">
						<Suspense fallback={<Preloader />}>{children}</Suspense>
					</main>

					<Footer />
				</div>
			</div>

			<Suspense fallback={loading()}>
				<RightSidebar />
			</Suspense>
		</Suspense>
	)
}
export default VerticalLayout
