import { LayoutMode } from '../../types'

type LayoutWidthProps = {
	handleChangeLayoutWidth: (value: LayoutMode) => void
	layoutWidth: LayoutMode
}

const LayoutWidth = ({
	handleChangeLayoutWidth,
	layoutWidth,
}: LayoutWidthProps) => {
	return (
		<div className="mb-4 2xl:block hidden">
			<h5 className="font-semibold text-sm mb-3">Width</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout-width"
						id="layout-mode-default"
						onChange={() => handleChangeLayoutWidth('fluid')}
						checked={layoutWidth === 'fluid'}
					/>
					<label className="ms-2" htmlFor="layout-mode-default">
						{' '}
						Fluid{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout-width"
						id="layout-mode-boxed"
						onChange={() => handleChangeLayoutWidth('boxed')}
						checked={layoutWidth === 'boxed'}
					/>
					<label className="ms-2" htmlFor="layout-mode-boxed">
						{' '}
						Boxed{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default LayoutWidth
