import { useForm } from 'react-hook-form'
import { PageTitle, FormInput, FileUploader, CategoryListSelectBox, LoadingOverlay } from '../../components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import f7WebNetwork from "../../common/api/customApi/f7WebNetwork";
import { useEffect, useRef, useState } from 'react'
import { QUILLMODULE } from '../../constants/htmlEditorModules';
import { useNavigate, useParams } from 'react-router-dom';
import globalTypes from '../../common/helpers/globalTypes';
import helperFunc from '../../common/helpers/helperFunc';
import { withSwal } from 'react-sweetalert2';
import ReactQuill from 'react-quill';



const InsertUpdateVideoGallery = withSwal((props: any) => {

	const { swal } = props
	const isDraftPost = useRef<boolean>(false);
	const mainImageFileUploadRef = useRef<any>();

	const [postCategoryList, setPostCategoryList] = useState<any>([]);
	const [postMainImage, setPostMainImage] = useState<any>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: null
	});
	const [postMainVideo, setPostMainVideo] = useState<any>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: null
	});
	const [loadingVisible, setLoadingVisible] = useState(false);
	const [videoHtmlContent, setVideoHtmlContent] = useState("");

	let { haberId, mode } = useParams();
	const navigate = useNavigate();

	const userInfo = helperFunc.getUserInformation();

	useEffect(() => {

		selectPostCategoryList();
	}, []);

	useEffect(() => {

		if (parseInt(haberId || "") > 0) {

			selectPostInformation();
		}

	}, [haberId]);

	const selectPostInformation = async () => {

		var params = {
			postId: parseInt(haberId || ""),
			userId: userInfo.UserId
		}
		var result = await f7WebNetwork.get("/videoGallery/get-by-id-from-admin", params);

		if (result.isSuccess) {



			const singlePost: any = result.data;

			var postimage = singlePost.mainImage;
			if (postimage) {
				setPostMainImage({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: helperFunc.getVideoGalleryImage(postimage)
				});
			}
			var postVideo = singlePost.videoName;
			if (postimage) {
				setPostMainVideo({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: postVideo
				});
			}

			setVideoHtmlContent(singlePost.htmlContent);

			setValue("selectedPostCategory", singlePost.categoryId);
			setValue("postTitle", singlePost.postTitle);
			setValue("postKeywords", singlePost.keywords);
			setValue("postShortDesc", singlePost.metaDescription);
			setValue("isIframe", singlePost.isIframe);
			setValue("duration", singlePost.duration);
			
			if (singlePost.isIframe) {
				setValue("videoName", singlePost.videoName);
			}


		} else {
			alert(result.errorMessage);
		}
	}


	const schemaResolver = yupResolver(
		yup.object().shape({
			selectedMainImage: yup.string(),
			postTitle: yup.string().required('Haber Başlığı giriniz'),
			postShortDesc: yup.string().required('Haber Kısa Açıklaması giriniz.'),
			selectedPostCategory: yup.string(),
			postKeywords: yup.string(),
			isIframe: yup.boolean(),
			videoName: yup.string(),
			duration : yup.string()
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors, },
		setValue,
		watch
	} = methods



	const selectPostCategoryList = () => {


		f7WebNetwork.get("/admin/selectAllCategory", null)
			.then(result => {
				var data = result.data;

				if (data) {
					setPostCategoryList(data);
				}
			})


	}

	const postNewsConfirm = async (values: any, status: boolean) => {

		setLoadingVisible(true);



		var postId = (parseInt(haberId || "0"))
		if (mode == "copy") {
			postId = 0;
		}



		const params = {
			Id: postId,
			PostTitle: values.postTitle,
			HtmlContent: videoHtmlContent,
			videoName: values.videoName,
			CategoryId: parseInt(values.selectedPostCategory || "0"),
			Keywords: values.postKeywords || "",
			MetaDescription: values.postShortDesc || "",
			Status: status,
			PostAuthor: userInfo.UserId,
			isIframe: values.isIframe,
			Duration : values.duration
		};
		console.log(params);
		let formData = new FormData();
		if (postMainImage.fileFrom == globalTypes.mainImageType.fromUpload && postMainImage.file) {
			formData.append("imagefile", postMainImage.file, postMainImage.file.name);
			// uploadedFiles.push(postMainImage.file);
		}

		if (postMainVideo.fileFrom == globalTypes.mainImageType.fromUpload && postMainVideo.file) {
			formData.append("videofile", postMainVideo.file, postMainVideo.file.name);

		}
		formData.append("parameters", JSON.stringify(params));


		let postResult: any;
		if (params.Id > 0) {
			postResult = await f7WebNetwork.postFormData("/videoGallery/update-from-admin", formData);
		} else {
			postResult = await f7WebNetwork.postFormData("/videoGallery/add-from-admin", formData);
		}

		isDraftPost.current = false;
		setLoadingVisible(false);


		if (postResult.isSuccess) {
			swal.fire({
				title: "Başarılı",
				confirmButtonText: "Ok",
				position: "top-end",
				icon: "success",
				text: "Haber başarılı bir şekilde eklendi..",
			});
			navigate("/videogaleri/list");
		} else {
			alert("Bağlantı hatası meydana geldi...");
			return;
		}

	}
	const handleFormData = (values: any) => {
		postNewsConfirm(values, false);
	}
	const habdleActiveStatusSave = (values: any) => {
		postNewsConfirm(values, true);
	}

	const setFieldValue = (name: any, value: any) => {


		setValue(name, value);

	}
	const checBoxFormProp = {
		onChange: (ev: any) => setFieldValue(ev.target.name, ev.target.checked),
		control: control,
		register: register
	}
	const checkKeyDown = (e:any) => {
        if (e.key === 'Enter') e.preventDefault();
      };
	return (
		<>
			<PageTitle title="Haber Ekle" />

			<LoadingOverlay
				isVisible={loadingVisible}
			/> 
			<form 	onKeyDown={checkKeyDown}
				className="valid-form "
				onSubmit={handleSubmit(handleFormData)}
			>
				<div className="form-group lg:col-span-2 flex justify-end rounded-sm overflow-hidden mb-3 ">

					<button type='submit'
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
					>
						<i className={`mdi mdi-content-save mr-2`} />
						Taslak Olarak Kaydet

					</button>

					<button type='button' onClick={handleSubmit(habdleActiveStatusSave)}
						className={`btn border-success rounded-md text-primary hover:bg-success hover:text-white ml-4`}
					>
						<i className={`mdi mdi-content-save mr-2`} />
						Haberi Yayınla

					</button>
				</div>

				<div className="card">
					<div className="p-6 pt-4">
						{/* <h4 className="card-title mb-6">Haber Bilgileri</h4> */}

						<div className='grid lg:grid-cols-2 mb-5 '>
							<div className='grid lg:grid-cols-1 gap-y-2 '>
								<div className="form-group">
									<FormInput
										name="selectedPostCategory"
										type="select"
										label="Haber Kategorisi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										key="select"
										errors={errors}
										control={control}
										register={register}
									>
										<CategoryListSelectBox categoryList={postCategoryList}></CategoryListSelectBox>
									</FormInput>
								</div>

								<div className="form-group">
									<FormInput
										name="postTitle"
										type="text"
										label="Haber Başlığı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="duration"
										type="text"
										label="Video Uzunluğu (Saniye cinsinden)"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<div className="flex items-center">
										<FormInput
											type="checkbox"
											name="isIframe"
											className="form-switch text-primary form-switch-lg"
											id="isIframe"
											{...checBoxFormProp}

										/>
										<label className="ms-1.5" htmlFor="isIframe">
											Embed Video Url mi?
										</label>
									</div>
								</div>
								<div className='form-group'>
									{watch("isIframe") ? (
										<FormInput
											name="videoName"
											type="textarea"
											label="Video Embed Kodu"
											labelClassName="text-gray-800 block mb-2"
											className="form-input"
											errors={errors}
											control={control}
											register={register}
										/>
									) : (
										// <FileUploader
										// 	isVideo={true}
										// 	isSingleFile={true}
										// 	icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
										// 	text="Haber Videous"

										// 	onFileUpload={(file) => setPostMainVideo({
										// 		file: file[0],
										// 		fileFrom: globalTypes.mainImageType.fromUpload
										// 	})}
										// />
										<>
											<div className='form-group flex items-center'>
												{postMainVideo.file && postMainVideo.fileFrom == globalTypes.mainImageType.fromRepository ? (
													// <i className='mdi mdi-video-check-outline text-4xl text-success mr-2' ></i>
													<video width="200" height={"150"} controls preload="metadata" className='mr-4'>
														<source src={helperFunc.getVideoGalleryImage(postMainVideo.file)} type="video/mp4" />
													</video>
												) : null}
												<span className='mr-2'>
													Haber Videosu:
												</span>
												<input
													ref={mainImageFileUploadRef}
													style={{}}
													title='Haber Fotoğrafı'
													type="file"
													// accept="image/*"
													accept="video/mp4,video/x-m4v,video/*"
													className="custom-file-input"
													id="inputGroupFile01"
													aria-describedby="inputGroupFileAddon01"
													onChange={(ev: any) => {
														setPostMainVideo({
															file: ev.target.files[0],
															fileFrom: globalTypes.mainImageType.fromUpload
														})
													}}
												/>
											</div>
										</>
									)}

								</div>

							</div>

							<div className='grid lg:grid-cols-1  ml-5 '>

								<div className="form-group">
									<FormInput
										name="postShortDesc"
										type="text"
										label="Haber Kısa Açıklaması"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group mt-3">
									<FormInput
										name="postKeywords"
										type="text"
										label="Anahtar Kelimeler"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<FileUploader
									isImage={true}
									isVideo={false}
									initialFileUrl={postMainImage.fileFrom == globalTypes.mainImageType.fromRepository ? postMainImage.file : ""}
									isSingleFile={true}
									icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
									text="Video Önizleme Fotoğrafı"

									onFileUpload={(file) => setPostMainImage({
										file: file[0],
										fileFrom: globalTypes.mainImageType.fromUpload
									})}
								/>
								<div className='h-[45px]'>

								</div>
							</div>


						</div>



					</div>
				</div>

				<div className='card h-auto'>
					<div className='p-6 mt-5'>
						<h4 className="card-title">Haber Yazısı</h4>
						<div className="">
							<div className="items-center h-[200px]">


								<ReactQuill
									modules={QUILLMODULE}
									value={videoHtmlContent}
									defaultValue={videoHtmlContent}
									theme="snow"
									className='h-[150px]'
									onChange={(value) => (setVideoHtmlContent(value))}
								/>

							</div>
						</div>
					</div>
				</div>
				<div className='mt-5'>

				</div>

			</form >

		</>
	)
})

export default InsertUpdateVideoGallery


