import { useRef } from "react";



type ComponentPros ={
    onFileComplete: Function,
    selectedFile : any
}
const ImageUploader = (props: ComponentPros) => {
    const {onFileComplete, selectedFile} = props;

    const mainImageFileUploadRef = useRef<any>();

    const fileToBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onFileUpload =async (event: any) => {

        var file_upload = event.target.files[0];

        
        const base64Str = await fileToBase64(file_upload);
        const file = file_upload;
        onFileComplete({
            base64Str, file
        });        
    };

    return (
        <div className="flex justify-content-center py-4" >

            <div className="col-lg-6 col-md-6 col-sm-12 flex items-center " >
                <button  type="button" className="  btn bg-dark text-white rounded-md" onClick={() =>
                    mainImageFileUploadRef.current.click()
                }>
             
                    <span className="ul-btn__text">
                        Fotoğraf Yükle
                    </span>
                </button>
                {selectedFile ?(
                  <div className="flex">
                      <img src={selectedFile} width={50} height={40} style={{
                        width: 60,
                        height: 43
                    }} className="ml-5"></img>

                
                    </div>
                ): null}

                <input
                    ref={mainImageFileUploadRef}
                    style={{ visibility: "hidden" }}
                    type="file"
                    // accept="image/*"
                    accept=".jpeg, .jpg, .png, .mp4"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={onFileUpload}
                />

            </div>
        </div>
    );

}
export default ImageUploader;