import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

// components
import PrivateRoute from './PrivateRoute'
import PhotoGalleryList from '../pages/photoGallery/PhotoGalleryList'
import InsertUpdatePhotoGallery from '../pages/photoGallery/InsertUpdatePhotoGallery'
import VideoGalleryList from '../pages/videoGallery/VideoGalleryList'
import InsertUpdateVideoGallery from '../pages/videoGallery/InsertUpdateVideoGallery'

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
// const Register = React.lazy(() => import('../pages/auth/Register'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
// const RecoverPassword = React.lazy(
// 	() => import('../pages/auth/RecoverPassword')
// )
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'))
// const ConfirmMail = React.lazy(() => import('../pages/auth/ConfirmMail'))

// // dashboard
const Dashboard = React.lazy(() => import('../pages/Dashboard'))
 
// // error
const Error404 = React.lazy(() => import('../pages/error/Error404'))
const Error500 = React.lazy(() => import('../pages/error/Error500'))

const InsertUpdateNews = React.lazy(() => import('../pages/news/InsertUpdateNews'))
const NewsList = React.lazy(() => import('../pages/news/NewsList')) 
const NewsOperations = React.lazy(() => import('../pages/news/NewsOperations')) 
const CommentList = React.lazy(() => import('../pages/news/CommentList')) 
const CategoryList = React.lazy(() => import('../pages/category/CategoryList')) 
const CategorySeo = React.lazy(() => import('../pages/category/CategorySeo')) 
const InsertUpdateCategory = React.lazy(() => import('../pages/category/InsertUpdateCategory')) 


export interface RoutesProps {
	path: RouteProps['path']
	name?: string
	element?: RouteProps['element']
	route?: any
	exact?: boolean
	icon?: string
	header?: string
	roles?: string[]
	children?: RoutesProps[]
}

// dashboards
const dashboardRoutes: RoutesProps = {
	path: '/admin',
	name: 'Dashboards',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/',
			name: 'Root',
			element: <NewsList />,
			route: PrivateRoute,
		},
	],
}

const newsRoutes: RoutesProps = {
	path: '/haber',
	name: 'News',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/haber/haberlistesi',
			name: 'NewsList',
			element: <NewsList />,
			route: PrivateRoute,
		},
		{
			path: '/haber/haberEkle/:haberId/:mode',
			name: 'InsertUpdateNews',
			element: <InsertUpdateNews />,
			route: PrivateRoute,
		},
		{
			path: '/haber/haberhareketleri',
			name: 'NewsOperations',
			element: <NewsOperations />,
			route: PrivateRoute,
		},
		{
			path: '/haber/yorumlar',
			name: 'CommentList',
			element: <CommentList />,
			route: PrivateRoute,
		},
	],
}
const photoGalleryRoutes: RoutesProps = {
	path: '/fotogaleri',
	name: 'photoGallery',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/fotogaleri/list',
			name: 'PhotoGalleryList',
			element: <PhotoGalleryList />,
			route: PrivateRoute,
		},
		{
			path: '/fotogaleri/ekle/:haberId/:mode',
			name: 'InsertUpdatePhotoGallery',
			element: <InsertUpdatePhotoGallery />,
			route: PrivateRoute,
		},
	],
}
const videoGalleryRoutes: RoutesProps = {
	path: '/videogaleri',
	name: 'VideoGallery',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/videogaleri/list',
			name: 'VideoGalleryList',
			element: <VideoGalleryList />,
			route: PrivateRoute,
		},
		{
			path: '/videogaleri/ekle/:haberId/:mode',
			name: 'InsertUpdateVideoGallery',
			element: <InsertUpdateVideoGallery />,
			route: PrivateRoute,
		},
	],
}
const categoryRoutes: RoutesProps = {
	path: '/kategori',
	name: 'kategori',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/kategori/list',
			name: 'CategoryList',
			element: <CategoryList />,
			route: PrivateRoute,
		},
		{
			path: '/kategori/seolist',
			name: 'CategorySeo',
			element: <CategorySeo />,
			route: PrivateRoute,
		},
		{
			path: '/kategori/ekle/:categoryId',
			name: 'InsertUpdateCategory',
			element: <InsertUpdateCategory />,
			route: PrivateRoute,
		},
		
	],
}

// // Apps
// const calendarAppRoutes: RoutesProps = {
// 	path: '/apps/calendar',
// 	name: 'Calendar',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'calendar',
// 	element: <CalendarApp />,
// 	header: 'Apps',
// }

// const chatAppRoutes: RoutesProps = {
// 	path: '/apps/chat',
// 	name: 'Chat',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'chat',
// 	element: <ChatApp />,
// 	header: 'Apps',
// }
// const projectAppRoutes: RoutesProps = {
// 	path: '/apps/project',
// 	name: 'Project',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'project',
// 	element: <Project />,
// 	header: 'Apps',
// }

// const emailAppRoutes: RoutesProps = {
// 	path: '/apps/email',
// 	name: 'Email',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'email',
// 	children: [
// 		{
// 			path: '/apps/email/inbox',
// 			name: 'Inbox',
// 			element: <EmailInbox />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/apps/email/templates',
// 			name: 'Read Email',
// 			element: <EmailTemplate />,
// 			route: PrivateRoute,
// 		},
// 	],
// }

// const tasksAppRoutes: RoutesProps = {
// 	path: '/apps/tasks',
// 	name: 'Tasks',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'task',
// 	children: [
// 		{
// 			path: '/apps/tasks/kanban',
// 			name: 'Kanban',
// 			element: <Kanban />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/apps/tasks/details',
// 			name: 'Details',
// 			element: <TasksDetails />,
// 			route: PrivateRoute,
// 		},
// 	],
// }

// const contactAppRoutes: RoutesProps = {
// 	path: '/apps/contacts',
// 	name: 'Contacts',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'contacts',
// 	children: [
// 		{
// 			path: '/apps/contacts/member-list',
// 			name: 'Member',
// 			element: <MembersList />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/apps/contacts/profile',
// 			name: 'Profile',
// 			element: <Profile />,
// 			route: PrivateRoute,
// 		},
// 	],
// }

// const appRoutes = [
// 	calendarAppRoutes,
// 	projectAppRoutes,
// 	chatAppRoutes,
// 	emailAppRoutes,
// 	tasksAppRoutes,
// 	contactAppRoutes,
// ]

// const WidgetRoutes: RoutesProps = {
// 	path: '/widgets',
// 	name: 'Widgets',
// 	route: PrivateRoute,
// 	roles: ['Admin'],
// 	icon: 'widget',
// 	element: <WidgetPage />,
// }
// // pages
// const customPagesRoutes = {
// 	path: '/pages',
// 	name: 'Pages',
// 	icon: 'pages',
// 	header: 'Custom',
// 	children: [
// 		{
// 			path: '/pages/invoice',
// 			name: 'Invoice',
// 			element: <InvoicePages />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/pages/faq',
// 			name: 'FAQs',
// 			element: <FAQPages />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/pages/timeline',
// 			name: 'Timeline',
// 			element: <TimelinePages />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/pages/pricing',
// 			name: 'Pricing',
// 			element: <PricingPages />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/pages/starter',
// 			name: 'Starter Page',
// 			element: <StarterPages />,∂src/assets/images/gallery/7.jpg
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: '/pages/gallery',
// 			name: 'Gallery',
// 			element: <GalleryPages />,
// 			route: PrivateRoute,
// 		},
// 	],
// }

// // ui
// const uiRoutes: RoutesProps = {
// 	path: '/ui',
// 	name: 'Components',
// 	icon: 'pocket',
// 	header: 'UI Elements',
// 	children: [
// 		{
// 			path: '/ui/base',
// 			name: 'Base UI',
// 			children: [
// 				{
// 					path: '/ui/tabs-accordions',
// 					name: 'Accordions',
// 					element: <TabsAndAccordions />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/notifications',
// 					name: 'Notifications',
// 					element: <Notifications />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/avatars',
// 					name: 'Avatars',
// 					element: <Avatars />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/badges',
// 					name: 'Badges',
// 					element: <Badges />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/buttons',
// 					name: 'Buttons',
// 					element: <Buttons />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/cards',
// 					name: 'Cards',
// 					element: <Cards />,
// 					route: PrivateRoute,
// 				},

// 				{
// 					path: '/ui/dropdowns',
// 					name: 'Dropdowns',
// 					element: <Dropdowns />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/embed-video',
// 					name: 'Embed Video',
// 					element: <EmbedVideo />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/list-group',
// 					name: 'List Group',
// 					element: <ListGroup />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/modals',
// 					name: 'Modals',
// 					element: <Modals />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/offcanvas',
// 					name: 'Offcanvas',
// 					element: <Offcanvas />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/placeholder',
// 					name: 'Placeholders',
// 					element: <Placeholders />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/tooltip-popovers',
// 					name: 'Popovers',
// 					element: <TooltipsAndPopovers />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/progress',
// 					name: 'Progress',
// 					element: <Progress />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/spinners',
// 					name: 'Spinners',
// 					element: <Spinners />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/typography',
// 					name: 'Typography',
// 					element: <Typography />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/images',
// 					name: 'Images',
// 					element: <Images />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/swiper',
// 					name: 'Swiper',
// 					element: <Swiper />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/extended-ui',
// 			name: 'Extended UI',
// 			children: [
// 				{
// 					path: '/extended-ui/range-slider',
// 					name: 'Range Slider',
// 					element: <RangeSlider />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/extended-ui/sweet-alert',
// 					name: 'Sweet Alert',
// 					element: <SweetAlert />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/extended-ui/draggable-cards',
// 					name: 'Draggable Cards',
// 					element: <Draggable />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/extended-ui/tour-page',
// 					name: 'Tour Page',
// 					element: <TourPage />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/extended-ui/tree-view',
// 					name: 'Tree View',
// 					element: <TreeViewPage />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/icons',
// 			name: 'Icons',
// 			children: [
// 				{
// 					path: '/ui/icons/mdi',
// 					name: 'Material Design Icons',
// 					element: <MaterialIcons />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/icons/mds',
// 					name: 'Material Design Symbol Icons',
// 					element: <MaterialSymbolsIcons />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/icons/lucide-icons',
// 					name: 'lucide Icons',
// 					element: <LucideIcons />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/icons/fai',
// 					name: 'Font Awesome Icons',
// 					element: <FAIcons />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/apexchart',
// 			name: 'Apex Charts',
// 			children: [
// 				{
// 					path: '/ui/apex/area',
// 					name: 'Area',
// 					element: <AreaApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/bar',
// 					name: 'Bar',
// 					element: <BarApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/bubble',
// 					name: 'Bubble',
// 					element: <BubbleApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/candlestick',
// 					name: 'Candlestick',
// 					element: <CandleStickApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/column',
// 					name: 'Column',
// 					element: <ColumnApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/heatmap',
// 					name: 'Heatmap',
// 					element: <HeatApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/line',
// 					name: 'Line',
// 					element: <LineApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/mixed',
// 					name: 'Mixed',
// 					element: <MixedApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/timeline',
// 					name: 'Timeline',
// 					element: <TimelineApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/boxplot',
// 					name: 'Boxplot',
// 					element: <BoxPlotApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/treemap',
// 					name: 'Treemap',
// 					element: <TreemapApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/pie',
// 					name: 'Pie',
// 					element: <PieApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/radar',
// 					name: 'Radar',
// 					element: <RadarApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/radialbar',
// 					name: 'RadialBar',
// 					element: <RadialbarApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/scatter',
// 					name: 'Scatter',
// 					element: <ScatterApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/polararea',
// 					name: 'Polar Area',
// 					element: <PolarAreaApex />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/apex/sparklines',
// 					name: 'Sparklines',
// 					element: <SparklinesApex />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/chartjs',
// 			name: 'Chartjs',
// 			children: [
// 				{
// 					path: '/ui/chartjs/area',
// 					name: 'Area',
// 					element: <AreaChartjs />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/chartjs/bar',
// 					name: 'Bar',
// 					element: <BarChartjs />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/chartjs/line',
// 					name: 'Line',
// 					element: <LineChartjs />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/chartjs/other',
// 					name: 'Other',
// 					element: <OtherChartjs />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/forms',
// 			name: 'Forms',
// 			children: [
// 				{
// 					path: '/ui/forms/general-elements',
// 					name: 'Basic Elements',
// 					element: <GeneralElements />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/advanced',
// 					name: 'Form Advanced',
// 					element: <FormAdvanced />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/validation',
// 					name: 'Validation',
// 					element: <Validation />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/wizard',
// 					name: 'Wizard',
// 					element: <Wizard />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/Quilljs-editor',
// 					name: 'Quilljs Editor',
// 					element: <QuillEditor />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/pickers',
// 					name: 'Pickers',
// 					element: <Pickers />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/forms/file-uploads',
// 					name: 'File Uploads',
// 					element: <FileUploads />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/tables',
// 			name: 'Tables',
// 			children: [
// 				{
// 					path: '/ui/tables/basic-tables',
// 					name: 'Basic Tables',
// 					element: <BasicTables />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/tables/data-tables',
// 					name: 'Data Tables',
// 					element: <DataTables />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 		{
// 			path: '/ui/maps',
// 			name: 'Maps',
// 			children: [
// 				{
// 					path: '/ui/maps/google-maps',
// 					name: 'Google Maps',
// 					element: <GoogleMaps />,
// 					route: PrivateRoute,
// 				},
// 				{
// 					path: '/ui/maps/vector-maps',
// 					name: 'Vector Maps',
// 					element: <VectorMaps />,
// 					route: PrivateRoute,
// 				},
// 			],
// 		},
// 	],
// }

// auth
const authRoutes: RoutesProps[] = [
	{
		path: '/auth/login',
		name: 'Login',
		element: <Login />,
		route: Route,
	},
	// {
	// 	path: '/auth/register',
	// 	name: 'Register',
	// 	element: <Register />,
	// 	route: Route,
	// },
	{
		path: '/auth/logout',
		name: 'Logout',
		element: <Logout />,
		route: Route,
	},
	// {
	// 	path: '/auth/recover-password',
	// 	name: 'Forgot Password',
	// 	element: <RecoverPassword />,
	// 	route: Route,
	// },
	{
		path: '/auth/lock-screen',
		name: 'Lock Screen',
		element: <LockScreen />,
		route: Route,
	},
	// {
	// 	path: '/auth/confirm-mail',
	// 	name: 'Confirm Mail',
	// 	element: <ConfirmMail />,
	// 	route: Route,
	// },
]

// public routes
const otherPublicRoutes = [
	{
		path: '*',
		name: 'Error - 404',
		element: <Error404 />,
		route: Route,
	},
	{
		path: 'pages/error-404',
		name: 'Error - 404',
		element: <Error404 />,
		route: Route,
	},
	{
		path: 'pages/error-500',
		name: 'Error - 500',
		element: <Error500 />,
		route: Route,
	},
	// {
	// 	path: '/pages/maintenance',
	// 	name: 'Maintenance',
	// 	element: <MaintenancePages />,
	// 	route: Route,
	// },
	// {
	// 	path: '/pages/coming-soon',
	// 	name: 'Coming Soon',
	// 	element: <ComingSoonPage />,
	// 	route: Route,
	// },
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
	let flatRoutes: RoutesProps[] = []

	routes = routes || []
	routes.forEach((item: RoutesProps) => {
		flatRoutes.push(item)
		if (typeof item.children !== 'undefined') {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
		}
	})
	return flatRoutes
}

// All routes
const authProtectedRoutes = [
	dashboardRoutes,
	newsRoutes,
	photoGalleryRoutes,
	videoGalleryRoutes,
	categoryRoutes,
	...otherPublicRoutes
	// ...appRoutes,
	// WidgetRoutes,
	// customPagesRoutes,
	// uiRoutes,
]
const publicRoutes = [...authRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
	publicRoutes,
	authProtectedRoutes,
	authProtectedFlattenRoutes,
	publicProtectedFlattenRoutes,
}

