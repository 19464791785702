import f7WebNetwork from '../../common/api/customApi/f7WebNetwork';
import helperFunc from '../../common/helpers/helperFunc';
import { LoadingOverlay, PageTitle } from '../../components'; 
import { useEffect, useState } from "react";
import { withSwal } from 'react-sweetalert2';
import DataTable from 'react-data-table-component';
import globalTypes from '../../common/helpers/globalTypes';
import { useNavigate } from 'react-router-dom';




const VideoGalleryList = withSwal((props: any) => {

    const navigate = useNavigate();

    const { swal } = props
    const [poostList, setPostList] = useState<Array<any>>([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
 
    useEffect(() => {
        selectPostListWithPaging();
    }, []);
    const selectUserInfo = () => {

        var user = helperFunc.getUserInformation();

        return user;

    }
    const userInfo: any = selectUserInfo();

    const selectPostListWithPaging = () => {

        var params = {
            userId: userInfo.UserId
        }
        setLoadingVisible(true);
        f7WebNetwork.get("/videoGallery/get-all-from-admin", params)
            .then(result => {
                setLoadingVisible(false);
                var data = result.data || [];
                setPostList(data);
    

            })
    }


    const changePostStatusFromLocal = (postId: number, status: boolean) => {
        var postListnew : Array<any> = JSON.parse(JSON.stringify(poostList));

        var postIndex = postListnew.findIndex(w => w.id == postId);
        if (postIndex != -1) {
            postListnew[postIndex].status = status;
            setPostList(postListnew);
        }
    }
   
    const newsMakePassive = (postId: number) => {


        if (window.confirm("Haber pasife alınacak. Devam edilsin mi?")) {
            setLoadingVisible(true);

            var params = {
                postId: postId,
                status: false,
                userId: userInfo.UserId
            };
            f7WebNetwork.get("/videoGallery/update-status-from-admin", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data: any = result;

                    if (data.isSuccess) {
                        swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Pasife alındı..",
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        changePostStatusFromLocal(postId, false);

                    } else {
                        setLoadingVisible(false);
                        alert(data.errorMessage);
                    }

                })
        }
    }

    const newsMakeActive = (postId: number) => {

        if (window.confirm("Haber Aktife alınacak. Devam edilsin mi?")) {
            setLoadingVisible(true);
            var params = {
                postId: postId,
                status: true,
                userId: userInfo.UserId
            };
     
            f7WebNetwork.get("/videoGallery/update-status-from-admin", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data: any = result;

                    if (data.isSuccess) {
                        swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Tamam",
                            position: "top-end",
                            icon: "success",
                            text: "Aktife Alındı..",
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        changePostStatusFromLocal(postId, true);

                    } else {
                        alert(data.errorMessage);
                    }
                })
        }
    } 

    const getDeleteButton = (row: any) => {

      
        if (row.status != true) {

            return (
                <>

                    <button type="button" className="" title='Haberi Aktife Al' onClick={() => newsMakeActive(row.id)}>
                        <i className="fa fa-cloud-upload text-lg text-primary" />
                    </button>
                </>
            );
        }
        return (

            <button type="button" className="" title='Haberi Pasife Al' onClick={() => newsMakePassive(row.id)}>
                <i className="fa fa-check-circle text-lg text-success" />
            </button>
        );
      
    }
 
    const geetActionButtons = (row: any) => {


        return (
            <div className='flex justify-content-between'>
                {getDeleteButton(row)}


                <button type="button" className="ml-3" title='Haberi Düzenle' onClick={() => {
                    navigate("/videogaleri/ekle/" + row.id + "/edit");
                }}>
                    <i className="fa fa-edit  text-lg" />
                </button>
                <button type="button" className="ml-3" title='Haberi Kopyala' onClick={() => {
                    navigate("/videogaleri/ekle/" + row.id + "/copy");
                }}>
                    <i className="fa fa-copy  text-lg" />
                </button>
                <button type="button" className="ml-3" title='Habere Git'
                    onClick={() => {
                        window.open(globalTypes.webProjectUrl + helperFunc.getPhotoGalleryUrl(row.postTitle, row.id));
                    }}>
                    <i className="fa fa-external-link text-lg" />
                </button>
            </div>
        );
    }

    return (
        <>
            <PageTitle title="Haber Listesi" />

            <div className="form-group lg:col-span-2 flex justify-start rounded-sm overflow-hidden mb-3 ">
                <button onClick={() => navigate("/videogaleri/ekle/0/new")}
                    className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}>
                    <i className={`mdi mdi-newspaper-plus mr-2`} />
                   Video Galeri Haberi Ekle
                </button>
            </div>
            <div className="card">
                <div className="p-6">



                    <div className='flex justify-between w-full mb-4'>
                        <h4 className="card-title mb-6">Haber Listesi</h4>
                    </div>
                    <div>
                        <LoadingOverlay
                            isVisible={loadingVisible}
                        />

                        <DataTable
                            data={poostList}
                            columns={[
                                {
                                    name: "Id",
                                    // label: "Id",
                                    selector: (row: any) => row.id,
                                    sortable: true,
                                    width: "100px",
                                },
                                {
                                    name: "Haber Başlığı",
                                    selector: (row: any) => row.postTitle,
                                    sortable: true,
                                },
                                {

                                    name: "Ekleme Tar.",
                                    width: "200px",
                                    selector: (row: any) => row.insertDate,
                                    sortable: true
                                },
                                {
                                    name: "Kategori Adı",
                                    width: "200px",
                                    selector: (row: any) => row.categoryName,
                                    // label: "Kategori Adı",
                                    sortable: true
                                },
                                {
                                    name: "Actions",
                                    width: "180px",
                                    selector: (row) => geetActionButtons(row),
                                    sortable: true
                                },
                            ]}
                            noDataComponent={<div> Herhangi bir haber bulunamadı </div>}
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50, 100]}
                            fixedHeader
                            fixedHeaderScrollHeight={(window.innerHeight - 350) + "px"}
                            pagination
                            responsive
                            // selectableRows
                            selectableRowsHighlight
                        />
                    </div>
                </div>
            </div>
        </>

    );
})
export default VideoGalleryList;