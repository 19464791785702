 


type ComponentProps = {
    isVisible: boolean,
}

const LoadingOverlay = (props: ComponentProps) => {


    if (props.isVisible) {
        return (
            <div className="overlay" >
                <div
                    key={"loading"}
                    className={`animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-primary rounded-full`}
                    role="status"
                    aria-label="loading"
                >
                    <span className="sr-only">Yükleniyor...</span>
                </div>
                <span className="text-white mt-4 font-bold">
                    Yükleniyor...
                </span>
            </div>
        );
    } else {
        return null;
    }

}
export default LoadingOverlay