import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import useFileUploader from './useFileUploader'
import React from 'react'

export interface FileType extends File {
	preview?: string
	formattedSize?: string,
	fileFrom?: number
}

interface FileUploaderProps extends ChildrenProps {
	onFileUpload?: (files: FileType[]) => void
	showPreview?: boolean,
	isSingleFile?: boolean,
	initialFileUrl?: any
}

type ChildrenProps = {
	icon?: string
	text?: string
	textClass?: string
	extraText?: string
	classname?: string
	isImage?: boolean,
	isVideo?: boolean
}

const imageAcceptArray = {
	'image/jpeg': [],
	'image/png': [],
	'image/webp': [],
	'image/heic': [],
	'image/jfif': [],
};
const videoAcceptArray = {
	'video/mp4': ['.mp4', '.MP4'],
}

const FileUploader = React.forwardRef((props: FileUploaderProps, ref) => {

	React.useImperativeHandle(ref, () => ({
     
    }));
	
	const { showPreview = true, onFileUpload, icon, text, isSingleFile, isImage, isVideo, initialFileUrl } = props;
	const { selectedFiles, handleAcceptedFiles, removeFile } =
		useFileUploader(showPreview)

	return (
		<div className='grid grid-col-1'>


			<Dropzone
				accept={isImage ? imageAcceptArray : isVideo ? videoAcceptArray : {}}
				multiple={isSingleFile ? false : true}
				onDrop={(acceptedFiles) => {
					handleAcceptedFiles(acceptedFiles, onFileUpload)
				}
				}
			>
				{({ getRootProps, getInputProps }) => (
					<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
						<div className="fallback">
							<input {...getInputProps()} name="file" accept="image/png, image/gif, image/jpeg" type="file" multiple={isSingleFile == true ? false : true} />
						</div>
						<div className="dz-message needsclick" {...getRootProps()}>
							<div
								className="mb-3"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<i className={icon}></i>
							</div>
							<h5 className="text-xl text-gray-600 dark:text-gray-200">
								{text}
							</h5>
						</div>
					</div>
				)}
			</Dropzone>

			{initialFileUrl ? (
				<React.Fragment key={"asdasdasd"}>
					<div className="border rounded-md border-gray-200 p-3 mb-2 dark:border-gray-600 mt-2">


						<div className="flex items-center gap-3">
							<img
								data-dz-thumbnail=""
								className="h-14 w-14 rounded bg-light"
								style={{ objectFit: 'cover' }}
								alt={""}
								src={initialFileUrl}
							/>

						</div>
					</div>
				</React.Fragment>
			) : null}
			{showPreview && selectedFiles.length > 0 && (
				<div>
					{(selectedFiles || []).map((file, idx) => {
						return (
							<React.Fragment key={idx}>
								<div className="border rounded-md border-gray-200 p-3 mb-2 dark:border-gray-600 mt-2">
									<div className="float-right">
										<Link to="" className="btn btn-link">
											<i
												className="mdi mdi-close text-lg"
												onClick={() => removeFile(file)}
											></i>
										</Link>
									</div>

									<div className="flex items-center gap-3">
										{file.preview && (
											<img
												data-dz-thumbnail=""
												className="h-12 w-12 rounded bg-light"
												style={{ objectFit: 'cover' }}
												alt={file.name}
												src={file.preview}
											/>
										)}
										{!file.preview && (
											<span className="flex items-center justify-center bg-primary/10 text-primary font-semibold rounded-md w-12 h-12">
												{file.type.split('/')[0]}
											</span>
										)}
										<div>
											<Link to="" className="font-semibold">
												{file.name}
											</Link>
											<p>{file.formattedSize}</p>
										</div>
									</div>
								</div>
							</React.Fragment>
						)
					})}
				</div>
			)}
		</div>
	)
})

export { FileUploader }
