import { useForm, } from 'react-hook-form'
import { PageTitle, FormInput, CategoryListSelectBox, LoadingOverlay, ImageUploader } from '../../components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import f7WebNetwork from "../../common/api/customApi/f7WebNetwork";
import { useEffect, useRef, useState } from 'react'
import { QUILLMODULE } from '../../constants/htmlEditorModules';
import { useNavigate, useParams } from 'react-router-dom';
import globalTypes from '../../common/helpers/globalTypes';
import helperFunc from '../../common/helpers/helperFunc';
import { withSwal } from 'react-sweetalert2';
import ReactQuill from 'react-quill';


type PhotoGalleryImages = {
	htmlContent: string,
	id: number,
	imageName: string,
	base64Image: string
}
const InsertUpdatePhotoGallery = withSwal((props: any) => {

	const { swal } = props
	const isLiveSharePost = useRef<boolean>(false);
	const mainImageFileUploadRef = useRef<any>();

	const [postCategoryList, setPostCategoryList] = useState<any>([]);
	const [postMainImage, setPostMainImage] = useState<any>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: null
	});
	const [photoGalleryImageList, setPhotoGalleryImageList] = useState<Array<PhotoGalleryImages>>([]);

	const [loadingVisible, setLoadingVisible] = useState(false);

	let { haberId, mode } = useParams();
	const navigate = useNavigate();

	const userInfo = helperFunc.getUserInformation();

	useEffect(() => {

		selectPostCategoryList();
	}, []);

	useEffect(() => {

		if (parseInt(haberId || "") > 0) {

			selectPostInformation();
		}

	}, [haberId]);

	const selectPostInformation = async () => {

		var params = {
			postId: parseInt(haberId || ""),
			userId: userInfo.UserId
		}
		var result = await f7WebNetwork.get("/photogallery/get-by-id-from-admin", params);

		if (result.isSuccess) {


			const singlePost: any = result.data;

			var postimage = singlePost.mainImage;
			if (postimage) {
				setPostMainImage({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: helperFunc.getPhotoGalleryImage(postimage)
				});
			}
			setPhotoGalleryImageList(singlePost.imageList);



			setValue("selectedPostCategory", singlePost.categoryId);
			setValue("postTitle", singlePost.postTitle);
			setValue("postKeywords", singlePost.keywords);
			setValue("postShortDesc", singlePost.metaDescription);


		} else {
			alert(result.errorMessage);
		}
	}


	const schemaResolver = yupResolver(
		yup.object().shape({
			selectedMainImage: yup.string(),
			postTitle: yup.string().required('Haber Başlığı giriniz'),
			postShortDesc: yup.string(),
			selectedPostCategory: yup.string(),
			postKeywords: yup.string(),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors, },
		setValue,
	} = methods



	const selectPostCategoryList = () => {


		f7WebNetwork.get("/admin/selectAllCategory", null)
			.then(result => {
				var data = result.data;

				if (data) {
					setPostCategoryList(data);
				}
			})


	}
	const UrlToBase64 = (url: any) => fetch(url)
		.then(response => response.blob())
		.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(reader.result)
			reader.onerror = reject
			reader.readAsDataURL(blob)
		}))

	const getMainImageFileUrl = () => {

		var file_upload = postMainImage.file;


		const url = URL.createObjectURL(file_upload);

		return url;
	}

	const getMainBase64 = async () => {

		const url = getMainImageFileUrl();
		const result = await UrlToBase64(url);
		return result;
	}

	const postNewsConfirm = async (values: any, status: boolean) => {

		setLoadingVisible(true);



		var postId = (parseInt(haberId || "0"))
		if (mode == "copy") {
			postId = 0;
		}
		var mainImage: any = "";
		if (postMainImage.file && postMainImage.fileFrom == globalTypes.mainImageType.fromUpload) {
			mainImage = await getMainBase64();
		}

		let imageList = [];
		for (let i = 0; i < photoGalleryImageList.length; i++) {
			const element = photoGalleryImageList[i];
			imageList.push({
				Id: element.id,
				ImageName: element.imageName || "",
				Base64Image: element.base64Image || "",
				HtmlContent: element.htmlContent || "",
			});
		}
		const params = {
			Id: postId,
			PostTitle: values.postTitle,
			MainImageBase64: mainImage,
			CategoryId: parseInt(values.selectedPostCategory || "0"),
			Keywords: values.postKeywords || "",
			MetaDescription: values.postShortDesc || "",
			Status: status,
			PostAuthor: userInfo.UserId,
			ImageList: imageList
		}

		let postResult: any;
		if (params.Id > 0) {
			postResult = await f7WebNetwork.post("/photogallery/update-from-admin", params);
		} else {
			postResult = await f7WebNetwork.post("/photogallery/add-from-admin", params);
		}

		isLiveSharePost.current = false;
		setLoadingVisible(false);
		if (postResult.isSuccess) {
			var res = postResult.data;
			if (res.isSuccess) {

				swal.fire({
					title: "Başarılı",
					confirmButtonText: "Ok",
					position: "top-end",
					icon: "success",
					text: "Haber başarılı bir şekilde eklendi..",
				});
				navigate("/fotogaleri/list");

			} else {
				alert(res.errorMessage);
			}
		} else {
			alert("Bağlantı hatası meydana geldi...");
			return;
		}

	}
	const handleFormData = (values: any) => {
		postNewsConfirm(values, false);
	}
	const habdleActiveStatusSave=(values: any)=>{
		postNewsConfirm(values, true);
	}
	const onMainImageUpload = (event: any) => {
		setPostMainImage({
			file: event.target.files[0],
			fileFrom: globalTypes.mainImageType.fromUpload
		})
	};

	const addPhotoGalleryImage = () => {
		var parsed = JSON.parse(JSON.stringify(photoGalleryImageList));
		parsed.push({
			htmlContent: "",
			id: 0,
			imageName: "",
			base64Image: ""
		});
		setPhotoGalleryImageList(parsed);
	}
	const onGalleryImageUpload = (index: number, res: any) => {

		var parsed = JSON.parse(JSON.stringify(photoGalleryImageList));
		parsed[index].base64Image = res.base64Str;
		setPhotoGalleryImageList(parsed);

	}
	const deleteGalleryImage = (index: number) => {

		var parsed = JSON.parse(JSON.stringify(photoGalleryImageList));
		parsed.splice(index, 1);
		setPhotoGalleryImageList(parsed);
	}
	const onGalleryImageHtmlChange = (index: number, value: any) => {

		var parsed = JSON.parse(JSON.stringify(photoGalleryImageList));
		parsed[index].htmlContent = value;
		setPhotoGalleryImageList(parsed);

	}


	const PhotoGalerySections = () => {

		return photoGalleryImageList.map((item, index) => {


			return (
				<div className='card mt-4 p-6 pt-4 h-[250px]' >
					<div className='flex justify-between' >
						<ImageUploader selectedFile={item.base64Image || item.imageName}
							onFileComplete={(res: any) => onGalleryImageUpload(index, res)} />

						<button type='button' className='mr-2' onClick={() => deleteGalleryImage(index)}>
							<i className='fa fa-trash-alt text-xl text-danger'></i>
						</button>
					</div>

					<ReactQuill
						modules={QUILLMODULE}
						defaultValue={item.htmlContent}
						theme="snow"
						className='h-[100px]'
						onChange={(value) => (onGalleryImageHtmlChange(index, value))}
					/>
				</div>

			);
		});

	}
	const checkKeyDown = (e:any) => {
        if (e.key === 'Enter') e.preventDefault();
      };
	return (
		<>
			<PageTitle title="Haber Ekle" />

			<LoadingOverlay
				isVisible={loadingVisible}
			/>
			<form
				onKeyDown={checkKeyDown}
				className="valid-form "
				onSubmit={handleSubmit(handleFormData)} >
				<div className="form-group lg:col-span-2 flex justify-end rounded-sm overflow-hidden mb-3 ">
					<button type='submit'
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}>
						<i className={`mdi mdi-content-save mr-2`} />
						Taslak Olarak Kaydet
					</button>
					<button type='submit' onClick={handleSubmit(habdleActiveStatusSave)}
						className={`btn border-success rounded-md text-primary hover:bg-success hover:text-white ml-4`}>
						<i className={`mdi mdi-content-save mr-2`} />
						Haberi Yayınla
					</button>
				</div>

				<div className="card">
					<div className="p-6 pt-4">
						{/* <h4 className="card-title mb-6">Haber Bilgileri</h4> */}

						<div className='grid lg:grid-cols-2 mb-5 '>
							<div className='grid lg:grid-cols-1 gap-y-2 '>
								<div className="form-group">
									<FormInput
										name="selectedPostCategory"
										type="select"
										label="Haber Kategorisi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										key="select"
										errors={errors}
										control={control}
										register={register}
									>
										<CategoryListSelectBox categoryList={postCategoryList}></CategoryListSelectBox>
									</FormInput>
								</div>

								<div className="form-group">
									<FormInput
										name="postTitle"
										type="text"
										label="Haber Başlığı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className='form-group flex items-center'>
									{postMainImage.file ? (
										<img
											src={postMainImage.fileFrom == globalTypes.mainImageType.fromRepository ? postMainImage.file :
												getMainImageFileUrl()} style={{
													width: 60,
													height: 60
												}} className='mr-4' />
									) : null}
									<span className='mr-2'>
										Haber Fotoğrafı Seçiniz:
									</span>
									<input
										ref={mainImageFileUploadRef}
										style={{}}
										title='Haber Fotoğrafı'
										type="file"
										// accept="image/*"
										accept=".jpeg, .jpg, .png, .mp4"
										className="custom-file-input"
										id="inputGroupFile01"
										aria-describedby="inputGroupFileAddon01"
										onChange={onMainImageUpload}
									/>
								</div>

							</div>

							<div className='grid lg:grid-cols-1  ml-5 '>

								<div className="form-group">
									<FormInput
										name="postShortDesc"
										type="text"
										label="Haber Kısa Açıklaması"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="postKeywords"
										type="text"
										label="Anahtar Kelimeler"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className='flex h-[50px]'></div>
							</div>


						</div>



					</div>
				</div>
				<h4 className="card-title mb-6 mt-6 ">Fotoğraflar</h4>
				<div className="">
					<div className="items-center">


						{PhotoGalerySections()}


						<div className='flex items-center w-full justify-center mt-6'>
							<button type='button' onClick={() => {
								addPhotoGalleryImage()
							}}
								className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
							>
								<i className={`mdi mdi-image-plus mr-2`} />  Fotoğraf Bölümü Ekle

							</button>
						</div>
					</div>
				</div>
				<div className='mt-5'>

				</div>

			</form>

		</>
	)
})

export default InsertUpdatePhotoGallery


