import React, { Suspense, useEffect } from 'react'
import { useThemeContext } from '../../common/context'
import { changeHTMLAttribute } from '../../utils'
import Preloader from '../../components/Preloader'
import { useViewport } from '../../hooks'

const Topbar = React.lazy(() => import('../Topbar'))
const Navbar = React.lazy(() => import('./Navbar'))
const Footer = React.lazy(() => import('../Footer'))
const RightSidebar = React.lazy(() => import('../RightSidebar'))
const LeftSidebar = React.lazy(() => import('../LeftSideBar'))

interface HorizontaLayoutProps {
	children?: any
}

// const loading = () => <div className="text-center"></div>
const HorizontalLayout = ({ children }: HorizontaLayoutProps) => {
	const { width } = useViewport()

	const { settings, updateTopbar } = useThemeContext()

	/*
	 * layout defaults
	 */
	useEffect(() => {
		changeHTMLAttribute('data-layout', 'horizontal')
		changeHTMLAttribute('data-topbar-color', 'dark')
		changeHTMLAttribute('data-leftbar-compact-mode', 'fixed')
		return () => {
			document.getElementsByTagName('html')[0].removeAttribute('data-layout')
		}
	}, [])

	useEffect(() => {
		if (settings.menuType == 'horizontal') updateTopbar('dark')
	}, [settings.menuType])

	useEffect(() => {
		changeHTMLAttribute('data-mode', settings.theme)
	}, [settings.theme])

	useEffect(() => {
		changeHTMLAttribute('data-layout-width', settings.mode)
	}, [settings.mode])

	useEffect(() => {
		changeHTMLAttribute('data-topbar-color', settings.topbarTheme)
	}, [settings.topbarTheme])

	useEffect(() => {
		changeHTMLAttribute('data-menu-color', settings.sidenav.theme)
	}, [settings.sidenav.theme])

	useEffect(() => {
		changeHTMLAttribute('data-layout-position', settings.sidenav.position)
	}, [settings.sidenav.position])

	useEffect(() => {
		changeHTMLAttribute('data-sidenav-view', settings.sidenav.mode)
	}, [settings.sidenav.mode])

	return (
		<Suspense fallback={<div />}>
			<div className="flex wrapper">
				<div className="app-menu">
					<Suspense fallback={<div />}>
						{width <= 1280 ? <LeftSidebar /> : <Navbar />}
					</Suspense>
				</div>

				<div className="page-content">
					<Suspense fallback={<div />}>
						<Topbar />
					</Suspense>

					<main className="p-6">
						<Suspense fallback={<Preloader />}>{children}</Suspense>
					</main>
					<Footer />
				</div>
			</div>

			<Suspense fallback={<div />}>
				<RightSidebar />
			</Suspense>
		</Suspense>
	)
}

export default HorizontalLayout
