import { useThemeContext } from '../../common'
import {
	LayoutDir,
	LayoutMode,
	LayoutSidenav,
	LayoutTheme as LayoutThemeType,
	LayoutTopbarTheme,
	MenuType,
} from '../../types'
import LayoutTheme from './LayoutTheme'
import LayoutDirection from './LayoutDirection'
import LayoutPosition from './LayoutPosition'
import LayoutWidth from './LayoutWidth'
import SideBarType from './SideBarType'
import SideBarTheme from './SideBarTheme'
import TopBarTheme from './TopBarTheme'
import LayoutType from './LayoutType'

const ThemeCustomizer = () => {
	const {
		settings,
		updateTheme,
		updateDir,
		updateSidenav,
		updateMode,
		updateTopbar,
		updateMenuType,
	} = useThemeContext()

	const handleChangeLayoutTheme = (theme: LayoutThemeType) => updateTheme(theme)
	const handleChangeLayoutDir = (dir: LayoutDir) => updateDir(dir)
	const handleChangeMenuType = (menuType: MenuType) => updateMenuType(menuType)
	const handleChangeMenuPosition = (position: LayoutSidenav['position']) =>
		updateSidenav({ position: position })
	const handleChangeLayoutWidth = (width: LayoutMode) => updateMode(width)
	const handleChangeSideBarType = (sidebarType: LayoutSidenav['mode']) =>
		updateSidenav({ mode: sidebarType })
	const handleChangeSideBarTheme = (sidebarTheme: LayoutSidenav['theme']) =>
		updateSidenav({ theme: sidebarTheme })
	const handleChangeTopBarTheme = (topbarTheme: LayoutTopbarTheme) =>
		updateTopbar(topbarTheme)
	return (
		<>
			<LayoutType
				handleChangeLayoutType={handleChangeMenuType}
				layoutType={settings.menuType}
			/>
			<LayoutTheme
				handleChangeLayoutTheme={handleChangeLayoutTheme}
				layoutTheme={settings.theme}
			/>
			<LayoutDirection
				handleChangeLayoutDir={handleChangeLayoutDir}
				layoutDirection={settings.dir}
			/>
			<LayoutPosition
				handleChangeMenuPosition={handleChangeMenuPosition}
				menuPosition={settings.sidenav.position}
			/>
			<LayoutWidth
				handleChangeLayoutWidth={handleChangeLayoutWidth}
				layoutWidth={settings.mode}
			/>
			<SideBarType
				handleChangeSidenavMode={handleChangeSideBarType}
				sidenavMode={settings.sidenav.mode}
			/>
			<SideBarTheme
				handleChangeSidenavTheme={handleChangeSideBarTheme}
				sidenavTheme={settings.sidenav.theme}
			/>
			<TopBarTheme
				handleChangeTopbarTheme={handleChangeTopBarTheme}
				topbarTheme={settings.topbarTheme}
			/>
		</>
	)
}

export default ThemeCustomizer
