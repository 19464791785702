 
const BorderSpinner = () => {
	return (
		<div className="card">
			<div className="p-6">
 				<div
					className="animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-current rounded-full"
					role="status"
					aria-label="loading"
				>
					<span className="sr-only"></span>
				</div>
			</div>
		</div>
	)
}

export default BorderSpinner