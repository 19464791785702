import { LayoutSidenav } from '../../types'

type SidenavModeProps = {
	handleChangeSidenavMode: (value: LayoutSidenav['mode']) => void
	sidenavMode: LayoutSidenav['mode']
}
const SideBarType = ({
	handleChangeSidenavMode,
	sidenavMode,
}: SidenavModeProps) => {
	return (
		<div className="mb-4" id="leftSidebarSize">
			<h5 className="font-semibold text-sm mb-3">Left Sidebar Size</h5>
			<div className="flex flex-col gap-3">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-sidenav-view"
						id="sidenav-view-default"
						onChange={() => handleChangeSidenavMode('default')}
						checked={sidenavMode === 'default'}
					/>

					<label className="ms-2" htmlFor="sidenav-view-default">
						{' '}
						Default{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-sidenav-view"
						id="sidenav-view-condensed"
						onChange={() => handleChangeSidenavMode('condensed')}
						checked={sidenavMode === 'condensed'}
					/>
					<label className="ms-2" htmlFor="sidenav-view-condensed">
						{' '}
						Condensed (Small){' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-sidenav-view"
						id="sidenav-view-compact"
						onChange={() => handleChangeSidenavMode('compact')}
						checked={sidenavMode === 'compact'}
					/>

					<label className="ms-2" htmlFor="sidenav-view-compact">
						{' '}
						Compact{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-sidenav-view"
						id="sidenav-view-mobile"
						onChange={() => handleChangeSidenavMode('mobile')}
						checked={sidenavMode === 'mobile'}
					/>

					<label className="ms-2" htmlFor="sidenav-view-mobile">
						{' '}
						Mobile{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-sidenav-view"
						id="sidenav-view-hidden"
						onChange={() => handleChangeSidenavMode('hidden')}
						checked={sidenavMode === 'hidden'}
					/>

					<label className="ms-2" htmlFor="sidenav-view-hidden">
						Hidden{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default SideBarType
