
export const QUILLMODULE = {
    toolbar: [
        [{ font: [] }, { size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'super' }, { script: 'sub' }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        ['direction', { align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
    ],
}