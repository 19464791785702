import { LayoutSidenav } from '../../types'

type SidenavThemeProps = {
	handleChangeSidenavTheme: (value: LayoutSidenav['theme']) => void
	sidenavTheme: LayoutSidenav['theme']
}
const SideBarTheme = ({
	handleChangeSidenavTheme,
	sidenavTheme,
}: SidenavThemeProps) => {
	return (
		<div className="mb-4">
			<h5 className="font-semibold text-sm mb-3">Menu Color</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-menu-color"
						id="menu-color-light"
						onChange={() => handleChangeSidenavTheme('light')}
						checked={sidenavTheme === 'light'}
					/>
					<label className="ms-2" htmlFor="menu-color-light">
						{' '}
						Light{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-menu-color"
						id="menu-color-dark"
						onChange={() => handleChangeSidenavTheme('dark')}
						checked={sidenavTheme === 'dark'}
					/>
					<label className="ms-2" htmlFor="menu-color-dark">
						{' '}
						Dark{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-menu-color"
						id="menu-color-brand"
						onChange={() => handleChangeSidenavTheme('brand')}
						checked={sidenavTheme === 'brand'}
					/>
					<label className="ms-2" htmlFor="menu-color-brand">
						{' '}
						Brand{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-menu-color"
						id="menu-color-gradient"
						onChange={() => handleChangeSidenavTheme('gradient')}
						checked={sidenavTheme === 'gradient'}
					/>
					<label className="ms-2" htmlFor="menu-color-gradient">
						{' '}
						Gradient{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default SideBarTheme
