import { ToastContainer } from 'react-toastify';
import { AuthProvider, ThemeProvider } from './common'
import AllRoutes from './routes/Routes'
import 'react-toastify/dist/ReactToastify.css';



// configureFakeBackend()
function App() {
	return (
		<ThemeProvider>
			<AuthProvider>
				<AllRoutes />
			</AuthProvider>
			<ToastContainer />
		</ThemeProvider>
	)
}

export default App
