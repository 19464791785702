import { LayoutTopbarTheme } from '../../types'

type TopbarThemeProps = {
	handleChangeTopbarTheme: (value: LayoutTopbarTheme) => void
	topbarTheme: LayoutTopbarTheme
}
const TopBarTheme = ({
	handleChangeTopbarTheme,
	topbarTheme,
}: TopbarThemeProps) => {
	return (
		<div className="mb-6">
			<h5 className="font-semibold text-sm mb-3">Topbar Color</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-topbar-color"
						id="topbar-color-light"
						onChange={() => handleChangeTopbarTheme('light')}
						checked={topbarTheme === 'light'}
					/>
					<label className="ms-2" htmlFor="topbar-color-light">
						{' '}
						Light{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-topbar-color"
						id="topbar-color-dark"
						onChange={() => handleChangeTopbarTheme('dark')}
						checked={topbarTheme === 'dark'}
					/>
					<label className="ms-2" htmlFor="topbar-color-dark">
						{' '}
						Dark{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default TopBarTheme
