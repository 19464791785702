import { Dropdown } from '../components'
type StatisticsCardProps = {
	cardTitle: string
	stats: number
	change: string
	variant: string
}
const StatisticsCard2 = ({
	cardTitle,
	change,
	stats,
	variant,
}: StatisticsCardProps) => {
	return (
		<div className="card">
			<div className="p-6">
				<div className="flex items-center justify-between mb-6">
					<h4 className="card-title">{cardTitle}</h4>
					<Dropdown />
				</div>
				<div className="flex items-center justify-between">
					<div
						className={`${variant} text-white rounded-full text-xs px-2 py-0.5`}
					>
						{change} <i className="mdi mdi-trending-up" />
					</div>
					<div className="text-end">
						<h2 className="text-3xl font-normal text-gray-800 dark:text-white mb-1">
							{' '}
							{stats}{' '}
						</h2>
						<p className="text-gray-400 font-normal">Revenue today</p>
					</div>
				</div>
				<div className="flex w-full h-[5px] bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 mt-6">
					<div
						className={`flex flex-col justify-center overflow-hidden ${variant}`}
						role="progressbar"
						style={{ width: '75%' }}
						aria-valuenow={25}
						aria-valuemin={0}
						aria-valuemax={100}
					/>
					<div
						className={`flex flex-col justify-center overflow-hidden ${variant}/10`}
						role="progressbar"
						style={{ width: '25%' }}
						aria-valuenow={15}
						aria-valuemin={0}
						aria-valuemax={100}
					/>
				</div>
			</div>
		</div>
	)
}

export default StatisticsCard2
