import { PopoverLayout } from '../components/HeadlessUI'
import { Link } from 'react-router-dom'

const Dropdown = () => {
	const PopoverToggle = () => {
		return <i className="mdi mdi-dots-vertical text-xl" />
	}
	return (
		<PopoverLayout placement="bottom-end" toggler={<PopoverToggle />}>
			<div
				id="dropdown-target6"
				className="w-max bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800"
			>
				<Link
					className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200"
					to=""
				>
					Action
				</Link>
				<Link
					className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300"
					to=""
				>
					Another action
				</Link>
				<Link
					className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300"
					to=""
				>
					Something else
				</Link>
				<Link
					className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300"
					to=""
				>
					Separated link
				</Link>
			</div>
		</PopoverLayout>
	)
}

export default Dropdown
