import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom'
// import { StrictMode } from 'react'
// styles
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

import './assets/scss/app.scss'
import './assets/scss/icons.scss'
import "./assets/scss/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter basename={''}>
		<App />
	</BrowserRouter>
);

// const container = document.getElementById('root')
// if (container) {
// 	const root = createRoot(container)
// 	root.render(
// 		// <StrictMode>
// 		<BrowserRouter basename={''}>
// 			<App />
// 		</BrowserRouter>
// 		// </StrictMode>
// 	)
// }
