import { ApexOptions } from 'apexcharts'
import { Dropdown } from '../components'
import ReactApexChart from 'react-apexcharts'

type StatisticsCardProps = {
	cardTitle: string
	stats: number
	classname: string
	chartSeries: number[]
	colors: string[]
}
const StatisticsCard = ({
	cardTitle,
	chartSeries,
	colors,
	stats,
	classname,
}: StatisticsCardProps) => {
	const apexOpts: ApexOptions = {
		chart: {
			height: 72,
			width: 72,
			type: 'donut',
		},
		legend: {
			show: false,
		},
		plotOptions: {
			pie: {
				donut: {
					size: '80%',
				},
			},
		},
		stroke: {
			colors: ['transparent'],
		},
		dataLabels: {
			enabled: false,
		},
		colors: colors,
	}
	return (
		<div className="card">
			<div className="p-6">
				<div className="flex items-center justify-between mb-11">
					<h4 className="card-title">{cardTitle}</h4>
					<div className="z-10">
						<Dropdown />
					</div>
				</div>
				<div className="flex items-center justify-between">
					<div dir="ltr">
						<ReactApexChart
							className={classname}
							options={apexOpts}
							series={chartSeries}
							type="donut"
							width={100}
							height={100}
						/>
					</div>
					<div className="text-end">
						<h2 className="text-3xl font-normal text-gray-800 dark:text-white mb-1">
							{' '}
							{stats}{' '}
						</h2>
						<p className="text-gray-400 font-normal">Revenue today</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StatisticsCard
