import { LayoutSidenav } from '../../types'

type LayoutPositionProps = {
	handleChangeMenuPosition: (value: LayoutSidenav['position']) => void
	menuPosition: LayoutSidenav['position']
}

const LayoutPosition = ({
	handleChangeMenuPosition,
	menuPosition,
}: LayoutPositionProps) => {
	return (
		<div className="mb-4">
			<h5 className="font-semibold text-sm mb-3">
				Layout Position (Menu and Topbar)
			</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout-position"
						id="layout-position-fixed"
						onChange={() => handleChangeMenuPosition('fixed')}
						checked={menuPosition == 'fixed'}
					/>
					<label className="ms-2" htmlFor="layout-position-fixed">
						{' '}
						Fixed{' '}
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout-position"
						id="layout-position-scrollable"
						onChange={() => handleChangeMenuPosition('scrollable')}
						checked={menuPosition == 'scrollable'}
					/>
					<label className="ms-2" htmlFor="layout-position-scrollable">
						{' '}
						Scrollable{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default LayoutPosition
