import { MenuType } from '../../types'

type LayoutTypeProps = {
	handleChangeLayoutType: (value: MenuType) => void
	layoutType: MenuType
}
const LayoutType = ({
	handleChangeLayoutType,
	layoutType,
}: LayoutTypeProps) => {
	return (
		<div className="mb-4">
			<h5 className="font-semibold text-sm mb-3">Choose Layout</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout"
						id="customizer-layout01"
						onChange={() => handleChangeLayoutType('vertical')}
						checked={layoutType == 'vertical'}
					/>
					<label className="ms-2" htmlFor="customizer-layout01">
						Vertical
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-layout"
						id="customizer-layout02"
						onChange={() => handleChangeLayoutType('horizontal')}
						checked={layoutType == 'horizontal'}
					/>
					<label className="ms-2" htmlFor="customizer-layout02">
						{' '}
						Horizontal{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default LayoutType
