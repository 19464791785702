import { useEffect, useState } from "react"

type ComponentProps ={
    categoryList : Array<any>,
    // selectedCategory : Number,
    // onSelectCategory: Function
}

const CategoryListSelectBox = ({ categoryList } : ComponentProps) => {

    const [configuredCategoryList, setConfiguredCategoryList] = useState<any>([]);
    //PARENTID
    useEffect(() => {
        if (categoryList && categoryList.length > 0) {
            configureCategoryList()
        }

    }, [categoryList]);

    const configureCategoryList = () => {

        var newList = [];

        var withoutParentsCategories = categoryList.filter(function (el) {
            return el.PARENTID == -1
        });
        var withParentsCategories = categoryList.filter(function (el) {
            return el.PARENTID !== -1
        });
        for (let i = 0; i < withoutParentsCategories.length; i++) {
            const element = withoutParentsCategories[i];

            newList.push({
                ...element,
                subList: []
            });
        }
        for (let i = 0; i < withParentsCategories.length; i++) {
            const element = withParentsCategories[i];
            var parentIdIndex = newList.findIndex(w => w.TERMID == element.PARENTID);
            if (parentIdIndex != -1) {
                newList[parentIdIndex].subList.push(element);
            } 
        }

   

        setConfiguredCategoryList(newList);
    }
 

    const getCategoryItem = () => {

        return configuredCategoryList.map((item: any, index: number) => {

            if (item.subList && item.subList.length > 0) {

                return (
                    <optgroup key={"item.subList" + index} label={item.NAME}>
                        {/* {item.TERMID == selectedCategory ? (
                            <option key={"sublist" + 1} selected value={item.TERMID}>{item.NAME}</option>
                        ) : ( */}
                            <option key={"sublist" + 1} value={item.TERMID}>{item.NAME}</option>
                        {/* )} */}
                        {item.subList.map((item : any, index: number) => (
                            // item.TERMID == selectedCategory ? (
                            //     <option key={"sublist" + index} selected value={item.TERMID}>{item.NAME}</option>
                            // ) : (
                                <option key={"sublist" + index} value={item.TERMID}>{item.NAME}</option>
                            // )
                        ))}
                    </optgroup>
                );
            } else
                // if (item.id == selectedCategory) {

                //     return (
                //         <option key={"selectedCategory" + index}  selected value={item.TERMID}>{item.NAME}</option>
                //     );
                // } else {
                    return (
                        <option key={"item.id" + index} value={item.TERMID}>{item.NAME}</option>
                    );
                // }
        }
        );
    }

    return ( 
         <>
            <option selected disabled>Kategori Seç</option>
            {getCategoryItem()} 
         </>
        
    );
}
export default CategoryListSelectBox;